import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import { Link } from 'modules/i18n';
import LanguageSwitcher from 'modules/i18n/components/LanguageSwitcher';
import useTranslation from 'next-translate/useTranslation';
import getConfig from 'next/config';
import { SiLinkedin, SiWhatsapp } from 'react-icons/si';

const { publicRuntimeConfig } = getConfig();

export default function Footer() {
  const { t } = useTranslation();

  const { linkedin, whatsappDorothee } = usePublicRuntimeConfig();

  const footerNavigation = {
    main: [
      { name: t('footer:privacy-policy'), href: '/privacy-policy' },
      { name: t('footer:terms'), href: '/terms-of-use' },
      { name: t('footer:general-conditions-of-sales'), href: '/general-conditions-of-sales' },
      // { name: t('footer:about'), href: '/about' },
      // { name: t('footer:affiliate'), href: '/affiliate' },
    ],
    social: [
      // {
      //   name: t('footer:join-community'),
      // },
      // {
      //   name: t('footer:community'),
      //   href: 'https://cryptofr.com/topic/35650/comparateur-de-taux-de-staking/5?_=1637637375260',
      //   icon: (props: any) => <GiFrance {...props} />,
      //   target: '_blank',
      //   rel: 'noopener noreferrer nofollow',
      // },
      // {
      //   name: 'Twitter',
      //   href: twitter,
      //   icon: (props: any) => <SiTwitter {...props} />,
      //   target: '_blank',
      //   rel: 'noopener noreferrer nofollow',
      // },
      {
        name: 'LinkedIn',
        href: linkedin,
        icon: (props: any) => <SiLinkedin {...props} />,
        target: '_blank',
        rel: 'noopener noreferrer nofollow',
      },
      // {
      //   name: 'Discord',
      //   href: 'https://discord.gg/rZG85NWPBM',
      //   icon: (props: any) => <SiDiscord {...props} />,
      //   target: '_blank',
      // },
      // {
      //   name: 'Telegram',
      //   href: telegram,
      //   icon: (props: any) => <SiTelegram {...props} />,
      //   target: '_blank',
      //   rel: 'noopener noreferrer nofollow',
      // },
      {
        name: 'WhatsApp',
        href: whatsappDorothee,
        icon: (props: any) => <SiWhatsapp {...props} />,
        target: '_blank',
        rel: 'noopener noreferrer nofollow',
      },
      // {
      //   name: 'Youtube',
      //   href: 'https://www.youtube.com/channel/UCIS0sH1Jy4z4hYd4H5sI3HA',
      //   icon: (props: any) => <SiYoutube {...props} />,
      //   target: '_blank',
      //   rel: 'noopener noreferrer nofollow',
      // },
      // {
      //   name: 'TikTok',
      //   href: 'https://www.tiktok.com/@stakingcrypto',
      //   icon: (props: any) => <SiTiktok {...props} />,
      //   target: '_blank',
      //   rel: 'noopener noreferrer nofollow',
      // },
      // {
      //   name: 'Facebook',
      //   href: 'https://www.facebook.com/stakingcryptoio',
      //   icon: (props: any) => <SiFacebook {...props} />,
      //   target: '_blank',
      //   rel: 'noopener noreferrer nofollow',
      // },
      // {
      //   name: 'Instagram',
      //   href: 'https://www.instagram.com/stakingcryptoio/',
      //   icon: (props: any) => <SiInstagram {...props} />,
      //   target: '_blank',
      //   rel: 'noopener noreferrer nofollow',
      // },
      // {
      //   name: 'IndieHackers',
      //   href: 'https://www.indiehackers.com/product/stakingcrypto-io',
      //   icon: (props: any) => (
      //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      //       <path d="M4 6H7V18H4V6Z" />
      //       <path d="M10 6H13V10.5H17V6H20V18H17V13.5H13V18H10V6Z" />
      //     </svg>
      //   ),
      //   target: '_blank',
      //   rel: 'noopener noreferrer nofollow',
      // },
      // {
      //   name: 'Reddit',
      //   href: 'https://www.reddit.com/r/BestStakingRates/',
      //   icon: (props: any) => <SiReddit {...props} />,
      //   target: '_blank',
      //   rel: 'noopener noreferrer nofollow',
      // },
    ],
  };
  return (
    <footer className="max-w-7xl mx-auto py-6 sm: mt-16 px-4 overflow-hidden sm:px-6 lg:px-8 border-t border-gray-100">
      <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
        {footerNavigation.main.map(({ name, href }) => (
          <div key={name} className="py-2 px-5">
            <Link href={href} prefetch={false} className="text-base text-gray-500 hover:text-gray-900">
              {name}
            </Link>
          </div>
        ))}
        <LanguageSwitcher className="py-2 px-5" />
      </nav>
      <div className="mt-8 flex flex-wrap justify-center items-center space-x-6">
        {footerNavigation.social.map(({ name, icon: Icon, href, ...item }) =>
          Icon ? (
            <a key={name} href={href} className="my-1 text-gray-400 hover:text-gray-500" {...item}>
              <span className="sr-only">{name}</span>
              <Icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ) : (
            <span key={name} className="my-1 text-xs text-gray-500">
              {name}
            </span>
          )
        )}
      </div>
      <p className="mt-8 text-center text-xs text-gray-500">
        &copy; depuis 2023{' '}
        <a href={publicRuntimeConfig.siteUrl} target="_blank" rel="noopener noreferrer" className="hover:text-gray-800">
          {publicRuntimeConfig.siteName}
        </a>
        . {t('footer:copyright')}
      </p>
    </footer>
  );
}
