import { Disclosure } from '@headlessui/react';
import classnames from 'classnames';
import { signOut, useAuthModal } from 'modules/auth';
import useNotifier from 'modules/common/hooks/useNotifier';
import { Link, useTranslation } from 'modules/i18n';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import { MdMenu, MdOutlineClose } from 'react-icons/md';
import usePublicRuntimeConfig from '../hooks/usePublicRuntimeConfig';
// import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
// import { SiTelegram, SiTwitter } from 'react-icons/si';

type HeaderProps = {
  // TODO
} & React.HTMLAttributes<HTMLDivElement>;

const Header: React.FC<HeaderProps> = ({ className, ...props }) => {
  const router = useRouter();
  // const { telegram, twitter } = usePublicRuntimeConfig();
  const { t } = useTranslation();
  const { session, toggleModal } = useAuthModal();
  const { notify } = useNotifier();
  const { siteName } = usePublicRuntimeConfig();

  const handleSignOut = async () => {
    try {
      await signOut({
        callbackUrl: `${window.location.origin}/fr`,
      });
      notify('success', 'You have been signed out');
    } catch (e: any) {
      notify('error', e.toString());
    }
  };

  return (
    <Disclosure as="header" className="bg-white shadow sticky top-0 z-30" {...props}>
      {({ open }) => (
        <nav>
          <div className="bg-white max-w-7xl mx-auto px-2 md:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">
              <div className="flex-1 flex items-center justify-between md:items-stretch space-x-2 md:space-x-4">
                <Link href="/" prefetch={false} className={classnames('shrink-0 items-center', router.pathname === '/' ? 'flex' : 'hidden md:flex')}>
                  <Image className="block h-12 w-auto" src="/logo/logo-wide-circled-orange.png" alt={siteName} height={60} width={240} />
                </Link>
                <Link
                  href="/"
                  prefetch={false}
                  className={classnames('shrink-0 flex items-center', router.pathname === '/' ? 'hidden' : 'md:hidden')}
                >
                  <Image className="block h-8 w-auto" src="/images/icons/favicon-32x32.png" alt={siteName} height={32} width={32} />
                </Link>
                <div className="flex gap-2 items-center">
                  <div className="flex-1 hidden md:flex md:space-x-4">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <Link
                      className={classnames('text-gray-500 inline-flex items-center px-1 text-sm font-bold hover:text-gray-800', {})}
                      href="/#faq"
                      prefetch={false}
                    >
                      FAQ
                    </Link>
                  </div>
                  <div className="flex-1 hidden md:flex md:space-x-4">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <Link
                      className={classnames('text-gray-500 inline-flex items-center px-1 text-sm font-bold hover:text-gray-800', {})}
                      href="/#pricing"
                      prefetch={false}
                    >
                      Tarifs
                    </Link>
                  </div>
                  <div className="flex-1 hidden md:flex md:space-x-4">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <Link
                      className={classnames('text-gray-500 inline-flex items-center px-1 text-sm font-bold hover:text-gray-800', {})}
                      href="/#testimonials"
                      prefetch={false}
                    >
                      Témoignages
                    </Link>
                  </div>
                  <div className="flex-1 hidden md:flex md:space-x-4">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <Link
                      className={classnames('text-gray-500 inline-flex items-center px-1 text-sm font-bold hover:text-gray-800', {})}
                      href="/blog"
                      prefetch={false}
                    >
                      Blog
                    </Link>
                  </div>
                </div>
                <div className="ml-auto items-center hidden md:flex">
                  <button
                    onClick={() => (!!session ? router.push('/my-missions') : toggleModal())}
                    className={classnames(
                      'leading-tight whitespace-nowrap bg-light border border-transparent rounded-md py-1 px-2 lg:py-2 lg:px-4 inline-flex flex-col items-center justify-center text-base font-medium text-primary hover:opacity-80'
                    )}
                    title={t('header:menu.signin.title')}
                  >
                    <span>{t('header:menu.signin.title')}</span>
                  </button>
                </div>
                <div className="flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 border-0">
                    <span className="sr-only">{t('header:menu.sr.open')}</span>
                    {open ? <MdOutlineClose className="block h-6 w-6" aria-hidden="true" /> : <MdMenu className="block h-6 w-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-4 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              {!!session ? (
                <>
                  {/* <Disclosure.Button as={Link} href="/affiliate">
                    <a
                      className={classnames('bg-indigo-50 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium', {
                        ['border-indigo-500']: router.pathname === '/affiliate',
                      })}
                    >
                      {t('header:menu.affiliation')}
                    </a>
                  </Disclosure.Button> */}
                  <Disclosure.Button
                    key="session_login"
                    as="button"
                    onClick={() => (!!session ? router.push('/my-missions') : toggleModal())}
                    className="bg-light text-primary block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  >
                    {t('header:menu.signin.title')}
                  </Disclosure.Button>
                  <Disclosure.Button
                    key="session_logout"
                    as="button"
                    onClick={handleSignOut}
                    className="border border-red-500 text-red-500 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  >
                    {t('header:menu.signout.title')}
                  </Disclosure.Button>
                </>
              ) : (
                <Disclosure.Button
                  key="session_login"
                  as="button"
                  onClick={toggleModal}
                  className={classnames('bg-light text-primary block pl-3 pr-4 py-2 border-l-4 text-base font-medium')}
                  title={t('header:menu.signin.title')}
                >
                  <span className="w-full">
                    {t('header:menu.signin.title')} {t('header:menu.free')}
                  </span>
                </Disclosure.Button>
              )}
            </div>
          </Disclosure.Panel>
        </nav>
      )}
    </Disclosure>
  );
};

export default Header;
